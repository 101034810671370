.no-more-tailwind {
  color: #d1d5db;
  font-family: "JetBrains Mono", monospace;
}

.no-more-tailwind a:link {
  color: #22d3ee;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.no-more-tailwind a:visited {
  color: #818cf8;
  text-decoration: none;
  cursor: pointer;
}

.no-more-tailwind a:link:active {
  color: #4ade80;
}

.no-more-tailwind a:visited:active {
  color: #818cf8;
}

.no-more-tailwind a:hover {
  text-shadow: 0 0 8px rgba(34, 211, 238, 0.5);
}

[class^="no-more-tailwind"] {
  display: block;
}

.no-more-tailwind area,
.no-more-tailwind datalist,
.no-more-tailwind head,
.no-more-tailwind link,
.no-more-tailwind param,
.no-more-tailwind script,
.no-more-tailwind style,
.no-more-tailwind title {
  display: none;
}

.no-more-tailwind b,
.no-more-tailwind strong {
  color: #4ade80;
  font-weight: bold;
}

.no-more-tailwind blockquote {
  margin: 1em 40px;
  border-left: 2px solid #4ade80;
  background-color: rgba(31, 41, 55, 0.3);
  border-radius: 0 4px 4px 0;
  padding: 1em;
}

.no-more-tailwind code,
.no-more-tailwind kbd,
.no-more-tailwind samp {
  font-family: "JetBrains Mono", monospace;
  background-color: rgba(31, 41, 55, 0.5);
  border: 1px solid rgba(74, 222, 128, 0.2);
  border-radius: 4px;
  padding: 0.2em 0.4em;
  color: #4ade80;
}

.no-more-tailwind code {
  text-shadow: 0 0 8px rgba(74, 222, 128, 0.3);
}

.no-more-tailwind em,
.no-more-tailwind i,
.no-more-tailwind cite,
.no-more-tailwind dfn,
.no-more-tailwind var {
  font-style: italic;
  color: #4ade80;
}

.no-more-tailwind h1 {
  font-size: 2em;
  margin: 0.67em 0;
  font-weight: bold;
  background: linear-gradient(to right, #4ade80, #60a5fa);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-shadow: 0 0 10px rgba(74, 222, 128, 0.3);
}

.no-more-tailwind h2 {
  font-size: 1.5em;
  margin: 0.83em 0;
}

.no-more-tailwind h3 {
  font-size: 1.17em;
  margin: 1em 0;
}

.no-more-tailwind h2,
.no-more-tailwind h3,
.no-more-tailwind h4,
.no-more-tailwind h5,
.no-more-tailwind h6 {
  font-weight: bold;
  color: #4ade80;
}

.no-more-tailwind hr {
  margin: 0.5em auto;
  border: 0;
  height: 1px;
  background: linear-gradient(
    to right,
    transparent,
    rgba(74, 222, 128, 0.5),
    transparent
  );
}

.no-more-tailwind img {
  display: inline-block;
  border: 1px solid rgba(74, 222, 128, 0.2);
  border-radius: 4px;
}

.no-more-tailwind ol {
  list-style-type: decimal;
}

.no-more-tailwind ul {
  list-style-type: none;
}

.no-more-tailwind ol,
.no-more-tailwind ul,
.no-more-tailwind menu {
  margin: 1em 0;
  padding-left: 40px;
}

.no-more-tailwind ul li {
  position: relative;
}

.no-more-tailwind ul li::before {
  content: ">";
  color: #4ade80;
  position: absolute;
  left: -20px;
}

.no-more-tailwind pre {
  font-family: "JetBrains Mono", monospace;
  white-space: pre;
  margin: 1em 0;
  background-color: rgba(31, 41, 55, 0.5);
  border: 1px solid rgba(74, 222, 128, 0.2);
  border-radius: 4px;
  padding: 1em;
  overflow-x: auto;
  color: #4ade80;
}

.no-more-tailwind table {
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid rgba(74, 222, 128, 0.2);
  border-radius: 4px;
  overflow: hidden;
}

.no-more-tailwind td,
.no-more-tailwind th {
  padding: 0.5em 1em;
}

.no-more-tailwind th {
  font-weight: bold;
  text-align: center;
  background-color: rgba(31, 41, 55, 0.5);
  color: #4ade80;
}

.no-more-tailwind tr:hover {
  background-color: rgba(31, 41, 55, 0.3);
}

.no-more-tailwind ::selection {
  background-color: rgba(74, 222, 128, 0.2);
  color: #4ade80;
}

.text-editor-wrapper {
  position: relative;
  color: inherit;
}

.rdw-editor-toolbar {
  background: rgba(31, 41, 55, 0.5) !important;
  border: 1px solid rgba(74, 222, 128, 0.2) !important;
  border-radius: 4px !important;
  padding: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.rdw-dropdown-wrapper,
.rdw-option-wrapper {
  background: rgba(31, 41, 55, 0.8) !important;
  border-color: rgba(74, 222, 128, 0.2) !important;
}

.rdw-dropdown-optionwrapper {
  background: rgba(31, 41, 55, 0.95) !important;
  border-color: rgba(74, 222, 128, 0.2) !important;
}

.rdw-option-wrapper:hover {
  box-shadow: 0 0 5px rgba(74, 222, 128, 0.3) !important;
}

.rdw-option-active {
  box-shadow: 0 0 5px rgba(74, 222, 128, 0.5) !important;
  background: rgba(74, 222, 128, 0.2) !important;
}

.editor-content {
  background: rgba(31, 41, 55, 0.3) !important;
  border: 1px solid rgba(74, 222, 128, 0.2) !important;
  border-radius: 4px !important;
  padding: 1rem !important;
  min-height: 200px !important;
  color: inherit !important;
}

.rdw-image-modal,
.rdw-link-modal {
  background: rgba(31, 41, 55, 0.95) !important;
  border-color: rgba(74, 222, 128, 0.2) !important;
  box-shadow: 0 0 10px rgba(74, 222, 128, 0.1) !important;
}

.rdw-image-modal-btn,
.rdw-link-modal-btn {
  background: #4ade80 !important;
  color: black !important;
  border: none !important;
  border-radius: 4px !important;
  padding: 6px 12px !important;
}

.rdw-image-modal-btn:hover,
.rdw-link-modal-btn:hover {
  background: #22c55e !important;
  box-shadow: 0 0 5px rgba(74, 222, 128, 0.5) !important;
}
