@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

html {
  scroll-behavior: smooth;
}

body {
  @apply text-lg;
}

.projects img {
  width: 100%;
}

/* color */
.bg-primary {
  background: #f3f1f1;
}

.bg-secondery {
  background: #c9cfd8;
}

.bg-accent {
  background: #7477ff;
}

.text-accent {
  color: #7477ff;
}

nav li a {
  @apply px-4 py-5 text-lg;
}

nav li a:hover {
  color: #7477ff;
}

.mobile-nav {
  @apply block md:hidden fixed top-10 py-2 w-full h-full bg-[#f3f1f1] duration-500;
}

/* hero */
/* h1 {
  font-family: "Pacifico", cursive;
  line-height: 1.5 !important;
} */

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
  min-height: 80vh;
  height: 100%;
}
.editor-class {
  background-color: lightgray;
  padding: 1rem;
  border: 1px solid #ccc;
  min-height: 80vh;
}
.toolbar-class {
  border: 1px solid #ccc;
}

.min-tap-target-size {
  min-width: 44px;
  min-height: 44px;
}

@media (max-width: 640px) {
  html {
    font-size: clamp(14px, 4vw, 16px);
  }
}

@keyframes text-glow {
  0%,
  100% {
    text-shadow: 0 0 10px #00ff9d;
  }
  50% {
    text-shadow: 0 0 20px #00ff9d, 0 0 30px #00ff9d;
  }
}

@keyframes cursor-blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.animate-text-glow {
  animation: text-glow 2s ease-in-out infinite;
}

.animate-cursor-blink {
  animation: cursor-blink 1s step-end infinite;
}

.shadow-cyber {
  box-shadow: 0 0 15px rgba(0, 255, 157, 0.2);
}

@keyframes progress {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}

.animate-progress {
  animation: progress 2s linear infinite;
}

.shadow-glow {
  filter: drop-shadow(0 0 2px #00ff9d);
}

.animate-pulse-slow {
  animation: pulse 3s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
